import React, { useState, useEffect } from "react";
import axios from "axios";
import API_BASE_URL from "../config/apiConfig";
import {
  Container,
  Typography,
  TextField,
  Button,
  Alert,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const AddProduct = () => {
  const [productData, setProductData] = useState({
    product_name: "", // Changed 'name' to 'product_name'
    type_id: "",
    color_id: "",
    size: "",
    mo_number: "",
    photos: [],
  });

  const [types, setTypes] = useState([]);
  const [colors, setColors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const typeResponse = await axios.get(`${API_BASE_URL}/types`);
        console.log("Types:", typeResponse.data);
        setTypes(typeResponse.data);

        const colorResponse = await axios.get(`${API_BASE_URL}/colors`);
        console.log("Colors:", colorResponse.data);
        setColors(colorResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setErrorMessage("Failed to fetch types and colors.");
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFileChange = (e) => {
    setProductData((prevState) => ({
      ...prevState,
      photos: Array.from(e.target.files),
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.keys(productData).forEach((key) => {
      if (key === "photos") {
        productData.photos.forEach((file) => formData.append("photos", file));
      } else {
        formData.append(key, productData[key]);
      }
    });

    axios
      .post(`${API_BASE_URL}/products`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setSuccessMessage(response.data.message);
        setProductData({
          product_name: "", // Reset to empty
          type_id: "",
          color_id: "",
          size: "",
          mo_number: "",
          photos: [],
        });
      })
      .catch((error) => {
        console.error("Error submitting product:", error);
        setErrorMessage(error.response?.data?.error || "An error occurred");
      });
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Add New Product
      </Typography>
      {successMessage && <Alert severity="success">{successMessage}</Alert>}
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Product Name"
              name="product_name" // Changed 'name' to 'product_name'
              value={productData.product_name}
              onChange={handleInputChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth required>
              <InputLabel>Type</InputLabel>
              <Select
                name="type_id"
                value={productData.type_id || ""}
                onChange={handleInputChange}
                displayEmpty
              >
                {types.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.type_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth required>
              <InputLabel>Color</InputLabel>
              <Select
                name="color_id"
                value={productData.color_id || ""}
                onChange={handleInputChange}
                displayEmpty
              >
                {colors.map((color) => (
                  <MenuItem key={color.id} value={color.id}>
                    {color.color_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Size"
              name="size"
              value={productData.size}
              onChange={handleInputChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="MO Number"
              name="mo_number"
              value={productData.mo_number}
              onChange={handleInputChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              accept="image/*"
              style={{ marginBottom: '16px' }}
            />
          </Grid>
          <Grid container justifyContent="flex-end" item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<AddCircleOutlineIcon />}
              size="large"
            >
              Add Product
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default AddProduct;

import React from "react";

const Orders = () => {
  return (
    <div>
      <h1>Orders Page</h1>
      <p>List of orders will be displayed here.</p>
    </div>
  );
};

export default Orders;

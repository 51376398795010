import axios from "axios";

const API_BASE_URL = "https://anoback.koneloneshin.com";
//const API_BASE_URL = "http://localhost:5001";
export default API_BASE_URL;

axios
  .get(`${API_BASE_URL}/types`)
  .then((response) => console.log(response))
  .catch((error) => console.error(error));

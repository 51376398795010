import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import API_BASE_URL from "../config/apiConfig";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
  Tooltip,
  Box,
  TablePagination,
  useTheme,
  DialogContentText,
  MenuItem,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [colors, setColors] = useState([]);
  const [nameSearch, setNameSearch] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalProducts, setTotalProducts] = useState(0);
  const [photoFiles, setPhotoFiles] = useState([]);

  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts();
    fetchProductTypes();
    fetchColors();
  }, [page, rowsPerPage]);

  useEffect(() => {
    filterProducts();
  }, [nameSearch, selectedType, selectedColor, products]);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/products?_page=${page + 1}&_limit=${rowsPerPage}`
      );
      const total = parseInt(response.headers["x-total-count"], 10);
      setTotalProducts(total);
      const productsWithPhotos = response.data.map((product) => ({
        ...product,
        photos: product.photos || [],
      }));
      setProducts(productsWithPhotos);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const fetchProductTypes = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/types`);
      setProductTypes(response.data);
    } catch (error) {
      console.error("Error fetching product types:", error);
    }
  };

  const fetchColors = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/colors`);
      setColors(response.data);
    } catch (error) {
      console.error("Error fetching colors:", error);
    }
  };

  const filterProducts = () => {
    const filtered = products.filter((product) => {
      const productName = product.product_name
        ? product.product_name.toLowerCase()
        : "";
      const typeMatches = selectedType
        ? product.type_id === selectedType
        : true;
      const colorMatches = selectedColor
        ? product.color_id === selectedColor
        : true;

      return (
        productName.includes(nameSearch.toLowerCase()) &&
        typeMatches &&
        colorMatches
      );
    });
    setFilteredProducts(filtered);
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpenImageDialog(true);
  };

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
    setSelectedImage(null);
  };

  const handleDeleteProduct = (id) => {
    setProductToDelete(id);
    setDeleteDialogOpen(true);
  };

  const confirmDeleteProduct = () => {
    if (productToDelete) {
      axios
        .delete(`${API_BASE_URL}/products/${productToDelete}`)
        .then(() => {
          fetchProducts();
          setDeleteDialogOpen(false);
          setProductToDelete(null);
        })
        .catch((error) => {
          console.error("Error deleting product:", error);
          alert("Failed to delete product. Please try again.");
        });
    }
  };

  const handleViewDetails = (id) => {
    navigate(`/products/${id}`);
  };

  const handleEditProduct = (product) => {
    setProductToEdit(product);
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
    setProductToEdit(null);
    setPhotoFiles([]);
  };

  const handleFileChange = (event) => {
    setPhotoFiles(Array.from(event.target.files));
  };

  const handleSaveEdit = () => {
    if (productToEdit) {
      const formData = new FormData();
      formData.append("product_name", productToEdit.product_name);
      formData.append("type_id", productToEdit.type_id);
      formData.append("color_id", productToEdit.color_id);
      formData.append("size", productToEdit.size);
      formData.append("mo_number", productToEdit.mo_number);

      photoFiles.forEach((file) => formData.append("photos", file));

      axios
        .put(`${API_BASE_URL}/products/${productToEdit.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          fetchProducts();
          handleCloseEditDialog();
        })
        .catch((error) => {
          console.error("Error updating product:", error);
        });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getTypeName = (typeId) => {
    const type = productTypes.find((type) => type.id === typeId);
    return type ? type.type_name : "Unknown";
  };

  const getColorName = (colorId) => {
    const color = colors.find((color) => color.id === colorId);
    return color ? color.color_name : "Unknown";
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom align="center">
        Product Management
      </Typography>

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            fullWidth
            label="Search by Name"
            value={nameSearch}
            onChange={(e) => {
              setNameSearch(e.target.value);
              filterProducts();
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            fullWidth
            select
            label="Filter by Type"
            value={selectedType}
            onChange={(e) => {
              setSelectedType(e.target.value);
              filterProducts();
            }}
          >
            <MenuItem value="">All Types</MenuItem>
            {productTypes.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.type_name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            fullWidth
            select
            label="Filter by Color"
            value={selectedColor}
            onChange={(e) => {
              setSelectedColor(e.target.value);
              filterProducts();
            }}
          >
            <MenuItem value="">All Colors</MenuItem>
            {colors.map((color) => (
              <MenuItem key={color.id} value={color.id}>
                {color.color_name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      <TableContainer component={Paper} style={{ marginTop: "20px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Photos</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>Color</TableCell>
              <TableCell>MO Number</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredProducts.map((product) => (
              <TableRow key={product.id}>
                <TableCell>
                  {product.photos && product.photos.length > 0 ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 1,
                        overflowX: "auto",
                        maxWidth: 300,
                      }}
                    >
                      {product.photos.map((photo, index) => (
                        <img
                          key={index}
                          src={`${API_BASE_URL}/uploads/${photo}`}
                          alt={`${product.product_name} - Photo ${index + 1}`}
                          style={{
                            width: 100,
                            height: "auto",
                            cursor: "pointer",
                            borderRadius: 4,
                            boxShadow: theme.shadows[1],
                          }}
                          onClick={() => handleImageClick(photo)}
                        />
                      ))}
                    </Box>
                  ) : (
                    <Box
                      width={100}
                      height={100}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      bgcolor={theme.palette.grey[300]}
                    >
                      <Typography variant="caption">No Image</Typography>
                    </Box>
                  )}
                </TableCell>
                <TableCell>{product.product_name}</TableCell>
                <TableCell>{getTypeName(product.type_id)}</TableCell>
                <TableCell>{product.size}</TableCell>
                <TableCell>{getColorName(product.color_id)}</TableCell>
                <TableCell>{product.mo_number}</TableCell>
                <TableCell>
                  <Tooltip title="View">
                    <IconButton
                      color="primary"
                      onClick={() => handleViewDetails(product.id)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit">
                    <IconButton
                      color="primary"
                      onClick={() => handleEditProduct(product)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteProduct(product.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalProducts}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      {/* Edit Dialog */}
      <Dialog open={editDialogOpen} onClose={handleCloseEditDialog}>
        <DialogTitle>Edit Product</DialogTitle>
        <DialogContent>
          {productToEdit && (
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Product Name"
                    value={productToEdit.product_name}
                    onChange={(e) =>
                      setProductToEdit({
                        ...productToEdit,
                        product_name: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    select
                    label="Type"
                    value={productToEdit.type_id}
                    onChange={(e) =>
                      setProductToEdit({
                        ...productToEdit,
                        type_id: e.target.value,
                      })
                    }
                  >
                    {productTypes.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.type_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    select
                    label="Color"
                    value={productToEdit.color_id}
                    onChange={(e) =>
                      setProductToEdit({
                        ...productToEdit,
                        color_id: e.target.value,
                      })
                    }
                  >
                    {colors.map((color) => (
                      <MenuItem key={color.id} value={color.id}>
                        {color.color_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Size"
                    value={productToEdit.size}
                    onChange={(e) =>
                      setProductToEdit({
                        ...productToEdit,
                        size: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="MO Number"
                    value={productToEdit.mo_number}
                    onChange={(e) =>
                      setProductToEdit({
                        ...productToEdit,
                        mo_number: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <input
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog}>Cancel</Button>
          <Button onClick={handleSaveEdit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={confirmDeleteProduct} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Image Dialog */}
      <Dialog open={openImageDialog} onClose={handleCloseImageDialog}>
        <DialogTitle>Product Image</DialogTitle>
        <DialogContent>
          {selectedImage && (
            <img
              src={`${API_BASE_URL}/uploads/${selectedImage}`}
              alt="Product"
              style={{ width: "100%", height: "auto" }}
            />
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default Products;

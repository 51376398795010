import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import API_BASE_URL from "../config/apiConfig";
import {
  Container,
  Typography,
  Grid,
  Paper,
  Button,
  Box,
  CircularProgress,
  Divider,
  Chip,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InfoIcon from "@mui/icons-material/Info";

const ProductDetail = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [productTypes, setProductTypes] = useState([]);
  const [colors, setColors] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProductDetails();
    fetchProductTypes();
    fetchColors();
  }, [id]);

  const fetchProductDetails = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/products/${id}`);
      console.log("API Response:", response.data); // Log the response data
      setProduct(response.data);
    } catch (error) {
      console.error("Error fetching product details:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchProductTypes = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/types`);
      setProductTypes(response.data);
    } catch (error) {
      console.error("Error fetching product types:", error);
    }
  };

  const fetchColors = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/colors`);
      setColors(response.data);
    } catch (error) {
      console.error("Error fetching colors:", error);
    }
  };

  const getTypeName = (typeId) => {
    const type = productTypes.find((type) => type.id === typeId);
    return type ? type.type_name : "Unknown";
  };

  const getColorName = (colorId) => {
    const color = colors.find((color) => color.id === colorId);
    return color ? color.color_name : "Unknown";
  };

  const handleBack = () => {
    navigate(-1);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!product) {
    return (
      <Container>
        <Typography variant="h6" align="center" color="error">
          Product not found.
        </Typography>
      </Container>
    );
  }

  const renderPhotos = () => {
    console.log("Product photos:", product.photos); // Add this line to check the photos data
    if (product.photos && product.photos.length > 0) {
      return (
        <Box display="flex" gap={2} overflow="auto" flexWrap="wrap">
          {product.photos.map((photo, index) => (
            <Box
              key={index}
              component="img"
              src={`${API_BASE_URL}/uploads/${photo}`}
              alt={`Photo ${index + 1}`}
              onError={(e) => {
                e.target.src = "path/to/placeholder-image.jpg"; // Fallback image
              }}
              sx={{
                width: { xs: "100%", sm: 300 }, // Responsive width
                height: "auto",
                borderRadius: 2,
                border: "1px solid #ddd",
                marginBottom: 2, // Space between images
              }}
            />
          ))}
        </Box>
      );
    }
    return (
      <Typography variant="caption" color="textSecondary">
        No images available
      </Typography>
    );
  };

  return (
    <Container>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={handleBack}
        variant="contained"
        color="primary"
        sx={{ marginTop: 2 }}
      >
        Back
      </Button>

      <Paper elevation={3} sx={{ padding: 3, marginTop: 3 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {renderPhotos()}
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              {product.product_name}
            </Typography>
            <Divider sx={{ my: 2 }} />

            <Typography variant="h6" gutterBottom>
              <Chip
                icon={<InfoIcon />}
                label="Details"
                sx={{ marginBottom: 1 }}
              />
            </Typography>

            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <strong>Name</strong>
                    </TableCell>
                    <TableCell>{product.product_name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Type</strong>
                    </TableCell>
                    <TableCell>{getTypeName(product.type_id)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Size</strong>
                    </TableCell>
                    <TableCell>{product.size}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Color</strong>
                    </TableCell>
                    <TableCell>{getColorName(product.color_id)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>MO Number</strong>
                    </TableCell>
                    <TableCell>{product.mo_number}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ProductDetail;
